import { createMuiTheme } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';

const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Inter'],
      },
    },
  },
  palette:{
    primary:{
      main: '#000000',
    },
    secondary: {
      main: '#707a83',
      fontWeight: '200',
    },
    background:{
      default: '#ffffff',
    },
  },

  overrides: {
      MuiButtonBase: {
          root: {
              justifyContent: 'flex-start',
          },
      },
      MuiButton: {
          root: {
              textTransform: undefined,
              padding: '12px 16px',
          },
          startIcon: {
              marginRight: 8,
          },
          endIcon: {
              marginLeft: 8,
          },
      },
      MuiDialogTitle: {
          root: {
              color: '#000',
              backgroundColor: '#ffffff!important',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)!important',
          },

      },
      MuiList: {
          root: {
              color: '#ffffff!important',
              backgroundColor: '#ffffff!important',

          },

      },
  },
});

export default theme;
