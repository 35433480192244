import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import Typography from '@material-ui/core/Typography';
import logo from "../images/logo_42b.png";
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  heroContent: {
    padding: theme.spacing(4, 0, 4),

  },
  heroText: {
    fontFamily: 'effra-cc, sans-serif',
    fontWeight: 800,
    fontColor: '#000000',

  },
  bookText: {
    fontFamily: 'cormorant-garamond, serif',
    fontWeight: 500,
    fontColor: '#000000',
    fontSize:'1.3rem',

  },

  divFlex:{
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    flexGrow: 1,
  },
  logo:{
    padding: theme.spacing(2, 0,1),
    justifyContent: "center",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    fontWeight: 200,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
    padding:  theme.spacing(3),
    fontStyle: 'italic',
  },
}));


const Hero = () => {
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();
  return(

    <Container maxWidth="sm" component="main" className={classes.heroContent}>


      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className={classes.heroText}>
           <Link href="https://www.forever.tips" style={{ textDecoration: 'none' }}>FOREVER</Link>
        </Typography>
        <Typography align="center" variant="subtitle1" className={classes.bookText}>

          You feel the temperol essence...

        </Typography>

      </Container>
      <Grid container spacing={1}>

        <Grid item xs={6} sm={3}>
          <Typography variant="h6" align="center" color="textPrimary" component="p" className={classes.heroText}>
            5000
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p" >
            SHAPES
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" align="center" color="textPrimary" component="p" className={classes.heroText}>
            2 SOL
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            MINT
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" align="center" color="textPrimary" component="p" className={classes.heroText}>
            60
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            FPS
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" align="center" color="textPrimary" component="p" className={classes.heroText}>
            10 SEC
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            LOOPS
          </Typography>
        </Grid>
      </Grid>









    </Container>

  );
}

export default Hero;
